import { Box, makeStyles, Container, Typography } from "@material-ui/core"
import React from "react"
import theme from "../../../plugins/digic-theme/theme"
import Fade from "react-reveal/Fade"

const useStyles = makeStyles({
  container: {
    display: "flex",
    margin: "128px 0",
    gap: "96px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "32px 0",
    },
  },
  textContainer: {
    maxWidth: "60%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "initial",
    },
  },
  timelineContainer: {
    marginLeft: "64px",
    marginTop: "64px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      marginTop: "32px",
      marginLeft: "initial",
    },
  },
})

export const AboutStory = ({ data: { title, description, milestones } }) => {
  const classes = useStyles()
  return (
    <Container>
      <Box className={classes.container}>
        <Box className={classes.textContainer}>
          <Typography
            variant="h2"
            style={{ width: "100%", marginBottom: "48px", maxWidth: "600px" }}
          >
            {title}
          </Typography>
          {console.log(description)}
          <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
            {description}
          </Typography>
        </Box>
        <Box className={classes.timelineContainer}>
          {milestones.map(milestone => (
            <Box key={milestone.milestone} display="flex">
              <Box
                width="20px"
                minWidth="20px"
                height="2px"
                style={{ background: "#fff", zIndex: 99 }}
                marginRight="-20px"
                marginTop="19px"
              />
              <Box borderLeft="3px dotted #444" pl={6} pb={12}>
                <Fade left>
                  <Typography variant="h2" style={{ paddingBottom: "4px" }}>
                    {milestone.year}
                  </Typography>
                  <Typography variant="caption">
                    {milestone.milestone}
                  </Typography>
                </Fade>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  )
}
