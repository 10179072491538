import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core"
import React from "react"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  serviceArea: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "0px",
      marginTop: "16px",
      marginBottom: "16px",
    },
    marginRight: "10%",
    marginBottom: "64px",
  },
  container: {
    margin: "128px auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
      marginBottom: "16px",
    },
  },
  servicesContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "64px",
    marginBottom: "64px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
      marginBottom: "24px",
    },
  },
  image: {
    [theme.breakpoints.down("xs")]: {
      width: "140px",
    },
  },
}))

export const AboutServiceAreas = ({ data: { title, areas } }) => {
  const classes = useStyles()
  return (
    <Container className={classes.container}>
      <Typography variant="h2">{title}</Typography>
      <Box className={classes.servicesContainer}>
        {areas.map(area => (
          <Box key={area.button_text} className={classes.serviceArea}>
            <img
              className={classes.image}
              src={area.logo.imageFile.publicURL}
              alt={area.button_text}
            />
            <Typography
              style={{ paddingBottom: "16px", paddingTop: "16px" }}
              variant="body1"
            >
              {area.description}
            </Typography>
            <Link to={area.LinkToService} style={{ textDecoration: "none" }}>
              <Button variant="outlined">{area.button_text}</Button>
            </Link>
          </Box>
        ))}
      </Box>
    </Container>
  )
}
