import { Box, Container, Typography, makeStyles } from "@material-ui/core"
import Img from "gatsby-image"
import React from "react"

const useStyles = makeStyles(theme => ({
  descriptionWrapper: {
    display: "flex",
    flexWrap: "wrap",
    margin: "32px 0 96px 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "48px 0",
    },
  },
  infoWrapper: {
    maxWidth: "60%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "initial",
    },
  },
  dataWrapper: {
    display: "flex",
    marginLeft: "10%",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "initial",
      marginLeft: "initial",
      marginTop: "16px",
    },
  },
  container: {
    margin: "96px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0px auto",
    },
  },
}))

export const AboutMain = ({
  data: { title, description, employee_count, image },
}) => {
  const classes = useStyles()
  return (
    <Container className={classes.container}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box className={classes.descriptionWrapper}>
          <Typography
            variant="h2"
            style={{ width: "100%", marginBottom: "48px", maxWidth: "600px" }}
          >
            {title}
          </Typography>
          <Box className={classes.infoWrapper}>
            <Typography variant="body1">{description}</Typography>
          </Box>
          <Box className={classes.dataWrapper}>
            <Box display="flex" flexDirection="column" width="100%">
              <Typography variant="caption" style={{ marginBottom: "8px" }}>
                Founded
              </Typography>
              <Typography variant="h3" style={{ marginBottom: "32px" }}>
                2002
              </Typography>
              <Typography variant="caption" style={{ marginBottom: "8px" }}>
                Founders
              </Typography>
              <Typography variant="h3">Andrew G. Vajna</Typography>
              <Typography variant="h3" style={{ marginBottom: "32px" }}>
                Rabb Sándor Alex
              </Typography>
              <Typography variant="caption" style={{ marginBottom: "8px" }}>
                Employees
              </Typography>
              <Typography variant="h3" style={{ marginBottom: "32px" }}>
                {employee_count}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Img
          style={{ width: "100%" }}
          fluid={image.imageFile.childImageSharp.fluid}
        />
      </Box>
    </Container>
  )
}
