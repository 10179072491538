import {
  Box,
  Container,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import Img from "gatsby-image"
import React from "react"
import theme from "../../../plugins/digic-theme/theme"

const useStyles = makeStyles(theme => ({
  image: {
    width: "25%",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  imageGridDesktop: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
  },
  imageGridMobile: {
    display: "grid",
    gridTemplateColumns: "1fr",
  },
  title: {
    marginBottom: "24px",
  },
}))

export const AboutOffice = ({
  data: {
    title,
    description,
    main_image,
    side1_image,
    side2_image,
    more_images,
  },
}) => {
  const classes = useStyles()

  const desktop = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Box>
      <Container>
        <Box mt={6} mb={8}>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" style={{ maxWidth: "900px" }}>
            {description}
          </Typography>
        </Box>
        <Box
          className={
            desktop ? classes.imageGridDesktop : classes.imageGridMobile
          }
        >
          <Box gridRow="span 2">
            <Img fluid={main_image.imageFile.childImageSharp.fluid}></Img>
          </Box>
          <Box>
            <Img fluid={side1_image.imageFile.childImageSharp.fluid}></Img>
          </Box>
          <Box>
            <Img fluid={side2_image.imageFile.childImageSharp.fluid}></Img>
          </Box>
        </Box>
      </Container>
      <Box display="flex" flexWrap="wrap" pt={16}>
        {more_images.map(image => (
          <Img
            className={classes.image}
            fluid={image.imageFile.childImageSharp.fluid}
          />
        ))}
      </Box>
    </Box>
  )
}

// {
//   title
//   description
//   main_image {
//     url
//     imageFile {
//       childImageSharp {
//         fluid(maxWidth: 2000) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
//   side1_image {
//     url
//     imageFile {
//       childImageSharp {
//         fluid(maxWidth: 2000) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
//   side2_image {
//     url
//     imageFile {
//       childImageSharp {
//         fluid(maxWidth: 2000) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
//   more_images {
//     url
//     imageFile {
//       childImageSharp {
//         fluid(maxWidth: 500) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// }
