import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { AboutMain } from "../components/about/about-main"
import { AboutOffice } from "../components/about/about-office"
import { AboutServiceAreas } from "../components/about/about-service-areas"
import { AboutStory } from "../components/about/about-story"
import Layout from "../components/layout/layout-home"
import Fade from "react-reveal/Fade"
import { Background, Parallax } from "react-parallax"
import { makeStyles } from "@material-ui/core"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        aboutDigic {
          title
          banner {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          main {
            title
            description
            employee_count
            image {
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          story {
            title
            description
            milestones {
              year
              milestone
            }
          }
          service_areas {
            title
            areas {
              LinkToService
              description
              button_text
              logo {
                url
                imageFile {
                  publicURL
                }
              }
            }
          }
          office {
            title
            description
            main_image {
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            side1_image {
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            side2_image {
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            more_images {
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const useStyles = makeStyles(theme => ({
    parallax: {
      height: "35vh",
      marginTop: "20px",
      [theme.breakpoints.down("sm")]: {
        height: "20vh",
      },
    },
    image: {
      maxHeight: "600px",
      height: "600px",
      width: "100vw",
      [theme.breakpoints.down("sm")]: {
        height: "300px",
      },
    },
  }))

  const { banner, main, story, service_areas, office } = data.strapi.aboutDigic
  const classes = useStyles()

  return (
    <Layout>
      <Fade>
        <div style={{ height: "100px" }} />
        <Parallax strength={500} className={classes.parallax}>
          <Background className="custom-bg">
            <Img
              className={classes.image}
              fluid={banner.imageFile.childImageSharp.fluid}
            />
          </Background>
        </Parallax>
        <AboutMain data={main} />
        <AboutStory data={story} />
        <AboutServiceAreas data={service_areas} />
        <AboutOffice data={office} />
      </Fade>
    </Layout>
  )
}

export default AboutPage
